import ApiService from "../../../../services/ApiService";
import translate from "../../../../services/Translate";

export const fetchCohortPartners = (
  cohortId: string,
  setPartnerInfo: Function,
  language: string,
  callback?: Function
) => {
  ApiService.get(`cohorts/${cohortId}/partners/infos`)
    .then((resp) => {
      setPartnerInfo(resp.data.partners_infos || []);
      callback?.();
    })
    .catch((error) => {
      console.error("An error occurred while fetching partner information", error);
      setPartnerInfo([]);
      alert(translate(language, "PAGES.COHORT.PARTNER_INFO.ERROR.FETCH_PARTNER"));
    });
};
