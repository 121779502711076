import { useEffect, useState } from "react";
import { Badge } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";

import DstButton from "../../components/DstButton/DstButton";
import ListerSearch from "../../components/Lister/ListerSearch/ListerSearch";
import LiveLister from "../../components/Lister/LiveLister";
import MultiFilterDropdown from "../../components/MultiFilterDropdown/MultiFilterDropdown";
import { useSession } from "../../contexts/SessionContext";
import { formatDateHumanly } from "../../services/TimeService";
import translate from "../../services/Translate";
import { completeNavigate } from "../../services/UtilityService";
import { fetchCohortsLeaders, fetchCohortsManagers, generateRemoteUrl, getIntensityColor } from "./Cohorts.function";
import "./Cohorts.scss";

export const Cohorts = () => {
  const { language } = useSession();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [programManagerFilter, setProgramManagerFilter] = useState<any[]>([]);
  const [cohortLeaderFilter, setCohortLeaderFilter] = useState<any[]>([]);
  const [selectedProgramManagerFilter, setSelectedProgramManagerFilter] = useState<any[]>([]);
  const [selectedCohortLeaderFilter, setSelectedCohortLeaderFilter] = useState<any[]>([]);
  const [loadedLeaders, setLoadedLeaders] = useState(false);
  const [loadedProgramManagers, setLoadedProgramManagers] = useState(false);
  const [detailedSearch, setDetailedSearch] = useState(false);

  const columns = [
    { header: translate(language, "PAGES.COHORTS.ID"), key: "id", sortable: true },
    { header: translate(language, "PAGES.COHORTS.NAME"), key: "name", sortable: true },
    ...(detailedSearch
      ? [
          {
            header: translate(language, "PAGES.COHORTS.DESCRIPTION"),
            key: "description",
          },
        ]
      : []),
    { header: translate(language, "PAGES.COHORTS.COHORT_LEADER"), key: "cohort_leader" },
    { header: translate(language, "PAGES.COHORTS.PROGRAM_MANAGER"), key: "program_manager" },
    {
      header: translate(language, "PAGES.COHORTS.SIZE"),
      key: "users_count",
      render: (item: any) => (
        <span>
          {item.users_count} <i className="dst-icon-users-01"></i>
        </span>
      ),
    },
    {
      header: translate(language, "PAGES.COHORTS.START_DATE"),
      key: "dt_start",
      sortable: true,
      render: (item: any) => (
        <span title={formatDateHumanly(language, item.start_date, true)}>
          {formatDateHumanly(language, item.start_date)}
        </span>
      ),
    },
    {
      header: translate(language, "PAGES.COHORTS.END_DATE"),
      key: "dt_end",
      sortable: true,
      render: (item: any) => (
        <span title={formatDateHumanly(language, item.end_date, true)}>
          {formatDateHumanly(language, item.end_date)}
        </span>
      ),
    },
    {
      header: translate(language, "PAGES.COHORTS.INTENSITY"),
      key: "intensity",
      render: (item: any) => (
        <Badge pill {...getIntensityColor(item.intensity)}>
          {item.intensity}
        </Badge>
      ),
    },
  ];

  useEffect(
    () => fetchCohortsLeaders(language, setCohortLeaderFilter, () => setLoadedLeaders(true)),
    [language, loadedLeaders]
  );
  useEffect(
    () => fetchCohortsManagers(language, setProgramManagerFilter, () => setLoadedProgramManagers(true)),
    [language, loadedProgramManagers]
  );

  return (
    <div className="cohorts-container m-5">
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex w-fit align-items-center justify-content-between mb-3">
          <h2 className="mb-0 me-3">Cohorts</h2>
          <ListerSearch setSearchQuery={setSearchQuery} timeDelay={500} showSearchIcon />
        </div>
        <div className="d-flex justify-content-end">
          <div className="me-2 d-flex align-items-center">
            <DstButton
              btnClass="h-100 d-flex align-items-center"
              btnImageBefore={
                <Form.Check
                  type="switch"
                  className="check-box s-bold me-2"
                  id="detailed-search-switch"
                  checked={detailedSearch}
                  onChange={() => setDetailedSearch(!detailedSearch)}
                  disabled={!loadedProgramManagers}
                />
              }
              value={translate(language, "PAGES.COHORTS.ENABLE_DETAILED_SEARCH")}
              variant="light"
              btnHeight="50px"
              btnWidth="150px"
              mock={!loadedProgramManagers}
              clickFunction={() => setDetailedSearch(!detailedSearch)}
            />
          </div>
          <div className="me-2">
            <MultiFilterDropdown
              tags={cohortLeaderFilter.map((leader: any) => ({
                display: leader.full_name,
                value: leader.id,
              }))}
              onSelectTags={(leader) => setSelectedCohortLeaderFilter(leader)}
              language={language}
              inputTitle={translate(language, "PAGES.COHORTS.FILTERS.LEADERS")}
              inputLabel={translate(language, "PAGES.COHORTS.FILTERS.INPUT_LABEL")}
              inputPlaceholder={translate(language, "PAGES.COHORTS.FILTERS.INPUT_PLACEHOLDER")}
              showSearchBar
              mock={!loadedLeaders}
            />
          </div>
          <div className="me-2">
            <MultiFilterDropdown
              tags={programManagerFilter.map((manager: any) => ({
                display: manager.full_name,
                value: manager.id,
              }))}
              onSelectTags={(manager) => setSelectedProgramManagerFilter(manager)}
              language={language}
              inputTitle={translate(language, "PAGES.COHORTS.FILTERS.PROGRAM_MANAGERS")}
              inputLabel={translate(language, "PAGES.COHORTS.FILTERS.INPUT_LABEL")}
              inputPlaceholder={translate(language, "PAGES.COHORTS.FILTERS.INPUT_PLACEHOLDER")}
              showSearchBar
              mock={!loadedProgramManagers}
            />
          </div>
        </div>
      </div>
      <LiveLister
        headerClass="text-nowrap"
        remote={generateRemoteUrl(
          searchQuery,
          selectedCohortLeaderFilter,
          selectedProgramManagerFilter,
          detailedSearch
        )}
        remoteKey="cohorts"
        remoteTransform={(cohort: any) => ({
          id: cohort.id,
          name: cohort.name,
          description: cohort.description,
          cohort_leader: cohort.cohort_leader.full_name,
          program_manager: cohort.program_manager?.full_name || "N/A",
          start_date: cohort.dt_start,
          end_date: cohort.dt_end,
          intensity: cohort.intensity,
          users_count: cohort.users_count,
        })}
        columns={columns}
        additionalButton={(item: any) => (
          <span
            className="clickable xs-regular"
            onClick={(event) => completeNavigate(event, navigate, `/cohort/${item.id}`)}
          >
            <span className="dst-icon-arrow-narrow-right" style={{ fontSize: "15px" }} />
          </span>
        )}
        showHeader
        pagination
        itemsPerPage={20}
      />
    </div>
  );
};

export default Cohorts;
