import React, { useEffect, useState } from "react";
import { OverlayTrigger, Placeholder, Popover } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import DstButton from "../../../../components/DstButton/DstButton";
import DstTable from "../../../../components/DstTable/DstTable";
import DstTableSearch from "../../../../components/DstTable/DstTableSearch/DstTableSearch";
import { formatMinutesHourly, getAgeHumanly } from "../../../../services/TimeService";
import translate from "../../../../services/Translate";
import { completeNavigate } from "../../../../services/UtilityService";
import { fetchUsers, fetchUsersLogtime, getUserActive, styleUserRow } from "./CohortUsers.function";
import "./CohortUsers.scss";

const CohortUsers = ({ cohortId, language }: { cohortId: string; language: string }) => {
  const navigate = useNavigate();
  const [cohortUsers, setCohortUsers] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingLogtime, setLoadingLogtime] = useState(false);
  const columns = [
    { name: translate(language, "PAGES.COHORT.USERS.TABLE_HEADER.LAST_NAME"), key: "last_name" },
    {
      name: translate(language, "PAGES.COHORT.USERS.TABLE_HEADER.FIRST_NAME"),
      key: "first_name",
    },
    { name: translate(language, "PAGES.COHORT.USERS.TABLE_HEADER.EMAIL"), key: "email" },
    {
      name: translate(language, "PAGES.COHORT.USERS.TABLE_HEADER.MODULES"),
      key: "validated_modules",
      render: (elem: any) => elem.validated_modules || "N/A",
    },
    {
      name: translate(language, "PAGES.COHORT.USERS.TABLE_HEADER.ACTIVE_TIME"),
      key: "total_time",
      render: (elem: any) =>
        elem.total_time !== undefined ? formatMinutesHourly(elem.total_time) : "Waiting to fetch",
    },
    {
      name: translate(language, "PAGES.COHORT.USERS.TABLE_HEADER.LAST_ACTIVITY"),
      key: "dt_last_seen",
      render: (elem: any) =>
        elem.dt_last_seen ? getAgeHumanly(language, new Date(elem.dt_last_seen).getTime()) : "N/A",
    },
    {
      name: translate(language, "PAGES.COHORT.USERS.TABLE_HEADER.ACTIVE"),
      key: "active",
      render: (elem: any) => getUserActive(elem, language),
    },
  ];
  const preProcessedData = cohortUsers.map((item: any) => ({ ...item, columns }));

  useEffect(() => {
    fetchUsers(cohortId, setCohortUsers, language, () => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <Placeholder animation="glow">
        <div className="mx-3">
          <div className="d-flex justify-content-between mb-3">
            <div className="d-flex">
              <Placeholder className="me-3 align-content-center mb-0" as="h2" xs={12} md={6} />
              <Placeholder as="input" xs={12} md={4} />
            </div>
            <DstButton mock btnWidth="100px" />
          </div>
        </div>
      </Placeholder>
    );
  }
  return (
    <div className="mx-3">
      <div className="d-flex justify-content-between mb-3">
        <div className="d-flex">
          <h2 className="align-content-center mb-0">{translate(language, "PAGES.COHORT.USERS.TITLE")}</h2>
          <h5 className="ms-2 me-3 align-content-center mb-0">( {!loading && cohortUsers.length} )</h5>
          <DstTableSearch
            data={preProcessedData}
            searchableColumns={["lastName", "firstName", "email", "active"]}
            onSearch={setFilteredData}
          />
        </div>
        <div className="d-flex">
          <DstButton
            btnImageBefore={<i className="dst-icon-copy-03 me-2" />}
            value={translate(language, "PAGES.COHORT.USERS.COPY_ALL_EMAILS")}
            variant="light"
            btnClass="me-2 d-flex align-items-center"
            clickFunction={() => {
              navigator.clipboard.writeText(cohortUsers.map((user) => user.email).join(", ")).catch((err) => {
                console.error("Failed to copy emails: ", err);
              });
            }}
          />
          <DstButton
            btnImageBefore={<i className="dst-icon-download-03 me-2" />}
            value={translate(language, "PAGES.COHORT.USERS.FETCH_LOGTIME")}
            variant="light"
            loading={loadingLogtime}
            btnClass="me-2 d-flex align-items-center"
            clickFunction={() =>
              fetchUsersLogtime(cohortId, cohortUsers, setCohortUsers, language, setLoadingLogtime, setFilteredData)
            }
          />

          <OverlayTrigger
            trigger="click"
            rootClose
            placement="bottom"
            overlay={
              <Popover id="color-code-popover">
                <Popover.Body className="s-bold">
                  <p className="d-flex align-items-center">
                    <div className="bg-primary-subtle color-circle me-2"></div> VIP
                  </p>
                  <p className="d-flex align-items-center mb-0">
                    <div className="bg-warning-subtle color-circle me-2"></div> Complicated
                  </p>
                </Popover.Body>
              </Popover>
            }
          >
            <div>
              <DstButton
                variant="light"
                btnClass="h-100 d-flex align-items-center"
                value={translate(language, "PAGES.COHORT.USERS.COLOR_CODE")}
                btnImageBefore={<i className="dst-icon-help-circle me-2"></i>}
              />
            </div>
          </OverlayTrigger>
        </div>
      </div>
      <DstTable
        data={filteredData}
        columns={columns}
        onClick={(event, item) => completeNavigate(event, navigate, `/user/${item.id}`)}
        classLine={(elem) => styleUserRow(elem)}
        striped={false}
      />
    </div>
  );
};

export default CohortUsers;
