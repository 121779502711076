import React from "react";
import { Placeholder, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

import "./DstButton.scss";

const DstButton = ({
  backgroundColor,
  btnClass = "s-bold",
  btnImageBefore,
  btnImageAfter,
  btnSize,
  btnWidth = "100%",
  btnHeight = "100%",
  clickFunction,
  color,
  disabled,
  link,
  loading = false,
  loadingColor,
  mock = false,
  target = "_self",
  type = "button",
  value = "Click here",
  variant = "primary",
}: {
  backgroundColor?: string;
  btnClass?: string;
  btnImageBefore?: JSX.Element;
  btnImageAfter?: JSX.Element;
  btnSize?: "lg" | "sm";
  btnWidth?: string;
  btnHeight?: string;
  clickFunction?: Function;
  color?: string;
  disabled?: boolean;
  link?: string;
  loading?: boolean;
  loadingColor?: string;
  mock?: boolean;
  target?: string;
  type?: "submit" | "button" | "reset" | undefined;
  value?: string;
  variant?: string;
}) => {
  const commonStyle = {
    backgroundColor: backgroundColor,
    color: color,
    width: btnSize,
  };

  const _determineClass = (btnClass: string) => {
    if (btnClass.includes("m-bold")) {
      return btnClass;
    }
    return `s-bold ${btnClass}`;
  };

  if (mock) {
    return (
      <Placeholder animation="glow" className="d-inherit">
        <Placeholder.Button
          className={_determineClass(btnClass)}
          style={{
            width: btnWidth,
            minHeight: btnHeight,
          }}
          aria-hidden="true"
        />
      </Placeholder>
    );
  }

  return link ? (
    link.startsWith("http") ? (
      <a className={_determineClass(btnClass)} style={commonStyle} href={link} target={target}>
        {value}
      </a>
    ) : (
      <Link className={_determineClass(btnClass)} style={commonStyle} to={link} target={target}>
        {value}
      </Link>
    )
  ) : (
    <Button
      variant={variant}
      className={_determineClass(btnClass)}
      size={btnSize}
      style={commonStyle}
      type={type}
      onClick={() => (clickFunction ? clickFunction() : null)}
      disabled={disabled}
    >
      {loading ? (
        <Spinner className="d-block" color={loadingColor || "white"} size={btnSize === "lg" ? undefined : "sm"} />
      ) : (
        <React.Fragment>
          {btnImageBefore}
          {value}
          {btnImageAfter}
        </React.Fragment>
      )}
    </Button>
  );
};

export default DstButton;
