import React, { useEffect, useState } from "react";
import { Col, Container, Modal, OverlayTrigger, Placeholder, Row, Spinner, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import CertifBlock from "../../components/CertifBlock/CertifBlock";
import CertifBlockOverview from "../../components/CertifBlockOverview/CertifBlockOverview";
import DstButton from "../../components/DstButton/DstButton";
import CheckIcon from "../../components/DstIcons/CheckIcon";
import ExternalLink from "../../components/DstIcons/ExternalLink";
import LockIcon from "../../components/DstIcons/LockIcon";
import Pin from "../../components/DstIcons/Pin";
import Plus from "../../components/DstIcons/Plus";
import Search from "../../components/DstIcons/Search";
import ThreeDots from "../../components/DstIcons/ThreeDots";
import Lister from "../../components/Lister/Lister";
import ListerSearch from "../../components/Lister/ListerSearch/ListerSearch";
import MeetingBlock from "../../components/MeetingBlock/MeetingBlock";
import ModuleBlock from "../../components/ModuleBlock/ModuleBlock";
import MultiSelectDropdown from "../../components/MultiSelectDropdown/MultiSelectDropdown";
import NoteContent from "../../components/NoteContent/NoteContent";
import NotesList from "../../components/NoteList/NoteList";
import NoteListOverview from "../../components/NoteListOverview/NoteListOverview";
import Popup from "../../components/Popup/Popup";
import ProjectBlock from "../../components/ProjectBlock/ProjectBlock";
import UserComponentActivity from "../../components/UserActivity/UserActivity";
import { Note } from "../../models/Note";
import ApiService from "../../services/ApiService";
import { PermissionComponent } from "../../services/PermissionGuard";
import { formatDateDifference, formatDateHumanly, formatMinutesHourly } from "../../services/TimeService";
import translate from "../../services/Translate";
import {
  createNote,
  deleteNote,
  fetchModules,
  getEvaluationLink,
  openDocument,
  openEmail,
  togglePinNote,
  updateNote,
  updateUserModules,
} from "./User.function";

export const UserOverview = ({
  user_data,
  lessonsLoaded,
  notesLoaded,
  certifsLoaded,
  setLoaded,
  language,
  setUserData,
  setActiveKey,
  setActiveNote,
}: {
  user_data: any;
  lessonsLoaded: boolean;
  notesLoaded: boolean;
  certifsLoaded: boolean;
  language: string;
  setUserData: Function;
  setActiveKey: (key: string) => void;
  setActiveNote: (key: Note) => void;
  setLoaded: Function;
}): JSX.Element => {
  const [modules, setModules] = useState<any[]>([]);
  const [isAdding, setIsAdding] = useState(false);

  useEffect(() => {
    fetchModules(setModules);
  }, []);
  const _normalizeString = (str: string) =>
    str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/\s+/g, "-")
      .toLowerCase()
      .replace(/[-:]+/g, "-");

  const _scrollToAnchor = (anchorId: string) =>
    document.getElementById(anchorId)?.scrollIntoView({ behavior: "smooth" });

  const _getSprintStatusProperties = (sprint: any) => {
    const dtEnd = new Date(sprint.dt_end);
    const dtStart = new Date(sprint.dt_start);
    const today = new Date();

    let circleColor = "gray-background";
    let iconComponent = <LockIcon color="gray" classes="position-absolute top-50 start-50 translate-middle" />;

    if (dtEnd < today) {
      circleColor = "green-background";
      iconComponent = <CheckIcon color="white" classes="position-absolute top-50 start-50 translate-middle" />;
    } else if (dtStart < today && dtEnd >= today) {
      circleColor = "yellow-background";
      iconComponent = <CheckIcon color="white" classes="position-absolute top-50 start-50 translate-middle" />;
    }

    return { circleColor, iconComponent };
  };
  const notes = user_data.user_notes;

  const _selectNote = (note: Note) => {
    setActiveKey("notes");
    setActiveNote(note);
  };

  return (
    <Row className="w-100">
      <Col md={9}>
        <div className="s-bold lightgray-font mb-3">{translate(language, "PAGES.USER.OVERVIEW.PROGRESS.TITLE")}</div>
        {lessonsLoaded ? (
          <React.Fragment>
            <div className="d-flex mx-0 mb-4">
              <div className="w-fit px-0 me-5">
                <div
                  onClick={() => {
                    _scrollToAnchor("user-modules");
                  }}
                >
                  <div className="p-4 border border-light rounded-circle position-relative gray-background mt-2">
                    <ThreeDots color="gray" classes="position-absolute top-50 start-50 translate-middle" />
                  </div>
                  <div className="text-center">{translate(language, "PAGES.USER.OVERVIEW.PROGRESS.OTHER")}</div>
                </div>
              </div>
              {user_data.user_sprints?.length > 0 && (
                <div
                  className={`d-flex px-0 mx-0 mt-2 position-relative justify-content-${
                    user_data.user_sprints?.length > 1 ? "between" : "center"
                  }`}
                  style={{
                    minWidth: 100,
                    overflowX: "auto",
                  }}
                >
                  {user_data.user_sprints?.length > 1 && (
                    <hr
                      className="position-absolute darkgray-font custom-line"
                      style={{
                        width: `calc(${user_data.user_sprints.length} * 70px + ${
                          (user_data.user_sprints.length - 1) * 1.5
                        }rem - 40px)`,
                      }}
                    />
                  )}

                  {user_data.user_sprints.map((sprint: any, index: number) => {
                    const { circleColor, iconComponent } = _getSprintStatusProperties(sprint);

                    return (
                      <div className="w-fit text-center px-0 min-w-70 me-4" key={`user-sprint-${index}`}>
                        <div onClick={() => _scrollToAnchor(_normalizeString(sprint.name))} className="clickable">
                          <OverlayTrigger overlay={<Tooltip id={`tooltip-${index}`}>{sprint.name}</Tooltip>}>
                            <div>
                              <div
                                className={`p-4 border border-light rounded-circle w-fit position-relative mx-auto ${circleColor}`}
                              >
                                {iconComponent}
                                <div className={`status-circle ${circleColor}`}></div>
                              </div>
                              <div>
                                Sprint{" "}
                                {index + 1}
                              </div>
                            </div>
                          </OverlayTrigger>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </React.Fragment>
        ) : (
          <div className="d-flex mb-4">
            {[...Array(5)].map((_, index) => (
              <Placeholder key={`sprint-${index}`} animation="glow" className={index === 0 ? "me-5" : "mx-4"}>
                <Placeholder as="img" height={50} width={50} style={{ borderRadius: "50%" }} />
                <Placeholder xs={12} role="mockup" />
              </Placeholder>
            ))}
          </div>
        )}

        <div className="d-flex justify-content-between align-items-center mb-3">
          <h3 className="mb-3" id="user-modules">
            {translate(language, "PAGES.USER.OVERVIEW.MODULE.TITLE")}
          </h3>
          <PermissionComponent neededPermission={[{ methods: ["PUT"], route: "/users/*/modules" }]}>
            <React.Fragment>
              {lessonsLoaded ? (
                <MultiSelectDropdown
                  label={
                    isAdding ? (
                      <React.Fragment>
                        <span className="pe-2">
                          {translate(language, "PAGES.USER.OVERVIEW.MODULE.ADDING_BUTTON_TITLE")}
                        </span>
                        <Spinner size="sm" />
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <span className="pe-2">
                          {translate(language, "PAGES.USER.OVERVIEW.MODULE.ADD_BUTTON_TITLE")}
                        </span>
                        <i className="dst-icon-plus-circle align-text-bottom"></i>
                      </React.Fragment>
                    )
                  }
                  items={modules}
                  disabled={isAdding}
                  displayItem={(item: any) => <div>{item.name}</div>}
                  badgeItemProperty="name"
                  toggleClass="btn-secondary"
                  clearOnConfirm
                  language={language}
                  hideCaret={true}
                  onConfirm={(selectedItems) => {
                    setIsAdding(true);
                    updateUserModules(
                      user_data.id,
                      [...user_data.user_modules.map((user_module: any) => user_module.module), ...selectedItems],
                      () => {
                        setIsAdding(false);
                        setLoaded((loaded: any) => ({
                          ...loaded,
                          lessons: false,
                        }));
                        ApiService.get(`users/${user_data.id}/lessons`)
                          .then((resp) => {
                            const user_lessons = resp.data.return;
                            setUserData((userData: any) => ({
                              ...userData,
                              user_sprints: user_lessons.sprints,
                              user_modules: user_lessons.user_modules,
                            }));
                          })
                          .catch((err) => console.error("An error occurred for fetching user's lessons", err))
                          .finally(() =>
                            setLoaded((loaded: any) => ({
                              ...loaded,
                              lessons: true,
                            }))
                          );
                      }
                    );
                  }}
                />
              ) : (
                <MultiSelectDropdown
                  badgeItemProperty=""
                  displayItem={(item: any) => <React.Fragment />}
                  items={[]}
                  label=""
                  language={language}
                  onConfirm={() => {}}
                  mock
                />
              )}
            </React.Fragment>
          </PermissionComponent>
        </div>
        {lessonsLoaded ? (
          <React.Fragment>
            {user_data.user_modules.map((module: any, index: number) => (
              <div key={`user-module-${index}`}>
                <ModuleBlock module={module} />
              </div>
            ))}
            {user_data.user_sprints.map((sprint: any, index: number) => (
              <React.Fragment key={`user-sprint-${index}`}>
                <hr />
                <h3 className="mb-3" id={_normalizeString(sprint.name)}>
                  {translate(language, "PAGES.USER.OVERVIEW.MODULE.SPRINT")}: {sprint.name}
                </h3>
                {sprint.modules.map((module: any, sub_index: number) => (
                  <div key={`user-sprint-${index}-module-${sub_index}`}>
                    <ModuleBlock module={module} />
                  </div>
                ))}
              </React.Fragment>
            ))}
          </React.Fragment>
        ) : (
          [...Array(3)].map((_, index) => <ModuleBlock key={index} mock={true} />)
        )}
      </Col>
      <Col md={3} className="ps-3 pt-3">
        <div className="d-flex justify-content-between align-items-center mb-1">
          <h3>{translate(language, "PAGES.USER.NOTE.TITLE")}</h3>
          <DstButton
            clickFunction={() => setActiveKey("notes")}
            btnClass="p-0"
            btnImageAfter={<ExternalLink color="#9C9C9C" classes="ms-2" />}
            color="#9C9C9C"
            value={`${translate(language, "PAGES.USER.NOTE.SEE_ALL")} (${notes?.length || 0})`}
            variant="transparent"
          />
        </div>
        {notesLoaded ? (
          notes && notes.length > 0 ? (
            <NoteListOverview
              notes={notes?.sort((first: Note, second: Note) => second.dt_created - first.dt_created)?.slice(0, 5)}
              language={language}
              selectNote={_selectNote}
              setActiveKey={setActiveKey}
            />
          ) : (
            <p>{translate(language, "PAGES.USER.NOTE.NO_NOTE")} </p>
          )
        ) : (
          <NoteListOverview mock={true} language={language} />
        )}

        <div className="d-flex justify-content-between align-items-center mb-1 mt-3">
          <h3>{translate(language, "PAGES.USER.CERTIFICATION.TITLE")}</h3>
          <DstButton
            clickFunction={() => setActiveKey("certif-exams")}
            btnClass="p-0"
            btnImageAfter={<ExternalLink color="#9C9C9C" classes="ms-2" />}
            color="#9C9C9C"
            value={`${translate(language, "PAGES.USER.CERTIFICATION.SEE_ALL")} (${
              user_data?.user_certifs?.length || 0
            })`}
            variant="transparent"
          />
        </div>
        <div className="certif-container">
          {certifsLoaded ? (
            user_data.user_certifs && user_data.user_certifs.length > 0 ? (
              user_data.user_certifs
                .slice(-5)
                .toReversed()
                .map((certif: any, index: number) => (
                  <CertifBlockOverview
                    key={index}
                    attempts={certif.attempt_count}
                    certifDate={certif.dt_created}
                    mainText={certif.certification_name}
                    remarkText={certif.duration ? `${certif.duration} min` : ""}
                    topLeftTitle={certif.sprint_name}
                  />
                ))
            ) : (
              <p>{translate(language, "PAGES.USER.CERTIFICATION.NO_CERTIFICATION")}</p>
            )
          ) : (
            [...Array(5)].map((_, index) => <CertifBlockOverview key={`certif-overview-${index}`} mock={true} />)
          )}
        </div>
      </Col>
    </Row>
  );
};

export const UserEmails = ({
  user_data,
  language,
  loaded,
  showEmailModal,
  setShowEmailModal,
  emailModalContent,
  setEmailModalContent,
}: {
  user_data: any;
  loaded: boolean;
  language: string;
  showEmailModal: boolean;
  setShowEmailModal: React.Dispatch<React.SetStateAction<boolean>>;
  emailModalContent: string;
  setEmailModalContent: React.Dispatch<React.SetStateAction<string>>;
}): JSX.Element => {
  const ref = React.useRef<any>();
  const [height, setHeight] = React.useState("0px");
  const _onLoad = () => {
    setHeight(`${ref.current.contentWindow.document.body.scrollHeight + 20}px`);
  };
  return loaded ? (
    <div className="w-100">
      <Lister
        items={user_data.user_emails.map((user_email: any) => ({
          ...user_email,
          recipients: user_email.recipients.filter((recipient: string) => recipient !== user_data.email).join(", "),
          date: user_email.date * 1000,
          status: user_email.is_read ? "read" : "not read",
        }))}
        columns={[
          { header: translate(language, "PAGES.USER.EMAIL_TABLE.RECIPIENT"), key: "recipients" },
          { header: translate(language, "PAGES.USER.EMAIL_TABLE.SUBJECT"), key: "subject" },
          {
            header: translate(language, "PAGES.USER.EMAIL_TABLE.DATE"),
            key: "date",
            render: (item: any) => (
              <div>
                <OverlayTrigger overlay={<Tooltip>{formatDateHumanly(language, item["date"], true)}</Tooltip>}>
                  <span>{formatDateHumanly(language, item["date"])}</span>
                </OverlayTrigger>
              </div>
            ),
          },
          { header: translate(language, "PAGES.USER.EMAIL_TABLE.ATTACHMENT"), key: "attachment" },
          {
            header: translate(language, "PAGES.USER.EMAIL_TABLE.STATUS"),
            key: "status",
            render: (item: any) => (
              <span className={`p-1 rounded ${item.status === "read" ? "status-green" : "status-red"}`}>
                {translate(language, `PAGES.USER.EMAIL_TABLE.${item.status === "read" ? "" : "NOT_"}READ`)}
              </span>
            ),
          },
        ]}
        additionalButton={(item: any) => (
          <span className="clickable" onClick={() => openEmail(item, setShowEmailModal, setEmailModalContent)}>
            <Search classes="search-icon" />
          </span>
        )}
        mock={!loaded}
      />
      <Popup
        show={showEmailModal}
        onClose={() => setShowEmailModal(false)}
        modalTitle="Email content"
        size="xl"
        fullscreen="lg-down"
        scrollable={true}
      >
        <iframe
          id="email-shower"
          title="email-shower"
          ref={ref}
          onLoad={_onLoad}
          srcDoc={emailModalContent}
          width="100%"
          height={height}
          style={{
            width: "100%",
            overflow: "auto",
          }}
        ></iframe>
      </Popup>
    </div>
  ) : (
    <Lister
      columns={[
        { header: translate(language, "PAGES.USER.EMAIL_TABLE.RECIPIENT"), key: "recipients" },
        { header: translate(language, "PAGES.USER.EMAIL_TABLE.SUBJECT"), key: "subject" },
        { header: translate(language, "PAGES.USER.EMAIL_TABLE.DATE"), key: "date" },
        { header: translate(language, "PAGES.USER.EMAIL_TABLE.ATTACHMENT"), key: "attachment" },
        { header: translate(language, "PAGES.USER.EMAIL_TABLE.STATUS"), key: "status" },
      ]}
      mock={true}
    />
  );
};

export const UserDocuments = ({
  user_data,
  language,
  loaded,
}: {
  user_data: any;
  loaded: boolean;
  language: string;
}): JSX.Element => (
  <Container fluid>
    {loaded ? (
      <Lister
        items={user_data.user_documents.map((user_document: any) => ({
          ...user_document,
          date: user_document.dt_updated || user_document.dt_created,
        }))}
        columns={[
          { header: translate(language, "PAGES.USER.DOCUMENT_TABLE.NAME"), key: "name" },
          {
            header: translate(language, "PAGES.USER.DOCUMENT_TABLE.DATE"),
            key: "date",
            render: (item: any) => (
              <div>
                <OverlayTrigger overlay={<Tooltip>{formatDateHumanly(language, item["date"], true)}</Tooltip>}>
                  <span>{formatDateHumanly(language, item["date"])}</span>
                </OverlayTrigger>
              </div>
            ),
          },
        ]}
        additionalButton={(item: any) => (
          <span className="clickable" onClick={() => openDocument(item.type, item.filename)}>
            <Search classes="search-icon" />
          </span>
        )}
        mock={!loaded}
      />
    ) : (
      <Lister
        columns={[
          { header: translate(language, "PAGES.USER.DOCUMENT_TABLE.NAME"), key: "name" },
          { header: translate(language, "PAGES.USER.DOCUMENT_TABLE.DATE"), key: "date" },
        ]}
        mock={true}
      />
    )}
  </Container>
);

export const UserMeetings = ({
  user_data,
  language,
  loaded,
}: {
  user_data: any;
  language: string;
  loaded: boolean;
}): JSX.Element => {
  const [filterType, setFilterType] = useState("");
  const meetingTypes: Array<string> = ["All"].concat(
    Array.from(new Set(user_data?.user_meetings?.map((meeting: any) => meeting.meeting_type)))
  );

  return (
    <div className="w-100 px-3">
      <div className="mb-3">
        {loaded ? (
          meetingTypes.map((type: string) => (
            <DstButton
              key={type}
              variant="info"
              clickFunction={() => setFilterType(type === "All" ? "" : type)}
              btnClass={`me-2 ${
                filterType === type || (filterType === "" && type === "All") ? "active-filter-btn" : ""
              }`}
              value={type}
            />
          ))
        ) : (
          <div className="d-flex">
            <DstButton mock btnWidth="41px" btnClass="me-2" />
            <DstButton mock btnWidth="90px" />
          </div>
        )}
      </div>
      <h2>{translate(language, "PAGES.USER.MEETING.UPCOMING_TITLE")}</h2>
      {loaded ? (
        user_data.user_meetings.filter(
          (meeting: any) => !!meeting.is_future && ["", meeting.meeting_type].includes(filterType)
        ).length > 0 ? (
          user_data.user_meetings
            .filter((meeting: any) => !!meeting.is_future && ["", meeting.meeting_type].includes(filterType))
            .map((meeting: any, index: number) => (
              <MeetingBlock key={`future-meeting-${index}`} {...meeting} language={language} />
            ))
        ) : (
          <p className="gray-font py-2">{translate(language, "PAGES.USER.MEETING.NO_MEETING_CORRESPONDING")}</p>
        )
      ) : (
        [...Array(3)].map((_elem, index) => (
          <MeetingBlock key={`mock-future-meeting-${index}`} mock={true} language={language} />
        ))
      )}

      <h2>{translate(language, "PAGES.USER.MEETING.PAST_TITLE")}</h2>
      {loaded ? (
        user_data.user_meetings.filter(
          (meeting: any) => !meeting.is_future && ["", meeting.meeting_type].includes(filterType)
        ).length > 0 ? (
          user_data.user_meetings
            .filter((meeting: any) => !meeting.is_future && ["", meeting.meeting_type].includes(filterType))
            .map((meeting: any, index: number) => (
              <MeetingBlock key={`past-meeting-${index}`} {...meeting} language={language} />
            ))
        ) : (
          <p className="gray-font py-2">{translate(language, "PAGES.USER.MEETING.NO_MEETING_CORRESPONDING")}</p>
        )
      ) : (
        [...Array(3)].map((_elem, index) => (
          <MeetingBlock key={`mock-past-meeting-${index}`} mock={true} language={language} />
        ))
      )}
    </div>
  );
};

export const UserProjects = ({
  user_data,
  language,
  loaded,
}: {
  user_data: any;
  language: string;
  loaded: boolean;
}): JSX.Element => {
  return (
    <div className="w-100">
      <h2 className="mb-3">{translate(language, "PAGES.USER.PROJECT.TITLE")}</h2>
      <div className="row w-100">
        {loaded ? (
          user_data.user_projects.length > 0 ? (
            user_data.user_projects.map((project: any, index: number) => (
              <div key={index} className="col-xl-4 col-md-6 col-sm-12 d-flex">
                <ProjectBlock
                  id={project.id}
                  type={project.type_name}
                  title={project.name}
                  description={project.description}
                  facilitator={project.overseer}
                  startDate={project.start_dt}
                  endDate={project.end_dt}
                  githubLink={project.github_link}
                />
              </div>
            ))
          ) : (
            <p>{translate(language, "PAGES.USER.PROJECT.NO_PROJECT")}</p>
          )
        ) : (
          <ProjectBlock
            mock
            id=""
            type=""
            title=""
            description=""
            facilitator=""
            startDate=""
            endDate=""
            githubLink=""
          />
        )}
      </div>
    </div>
  );
};

export const UserActivity = ({ user_data, language }: { user_data: any; language: string }): JSX.Element => {
  return (
    <div className="w-100">
      <UserComponentActivity
        language={language}
        mock={!user_data.user_modules}
        userName={user_data.first_name}
        userId={user_data.id}
        userModules={user_data.user_modules}
      />
    </div>
  );
};

export const UserExams = ({
  user_data,
  loaded,
  language,
}: {
  user_data: any;
  loaded: boolean;
  language: string;
}): JSX.Element => {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [selectedComment, setSelectedComment] = useState({
    content: "",
    date: "",
    corrector_name: "",
    corrector_id: null,
  });
  const nowDate = new Date();

  const _getStatus = (status: string): string => {
    switch (status?.toLowerCase()) {
      case "in progress":
      case "correction pending":
      case "being corrected":
        return "status-orange";
      case "corrected":
      case "success":
        return "status-green";
      case "repass":
      case "failed":
        return "status-red";
      case "created":
        return "status-blue";
      default:
        return "";
    }
  };

  return (
    <div className="w-100">
      <h2>{translate(language, "PAGES.USER.EXAM_TABLE.TITLE")}</h2>
      {loaded ? (
        user_data.user_exams.length > 0 ? (
          <Lister
            items={user_data.user_exams.map((user_exam: any) => ({
              ...user_exam,
              token: user_exam.id.toString(),
              creation_date: user_exam.dt_sent,
              start_date: user_exam.dt_start,
              duration: formatDateDifference(user_exam.dt_start, user_exam.dt_ended || nowDate),
              status: user_exam.status.replace(/_/g, " "),
            }))}
            columns={[
              {
                header: translate(language, "PAGES.USER.EXAM_TABLE.NAME"),
                key: "name",
              },
              { header: translate(language, "PAGES.USER.EXAM_TABLE.TOKEN"), key: "token" },
              {
                header: translate(language, "PAGES.USER.EXAM_TABLE.CREATION_DATE"),
                key: "creation_date",
                sortType: "date",
                render: (item: any) => (
                  <div>
                    <OverlayTrigger
                      overlay={<Tooltip>{formatDateHumanly(language, item["creation_date"], true)}</Tooltip>}
                    >
                      <span>{formatDateHumanly(language, item["creation_date"])}</span>
                    </OverlayTrigger>
                  </div>
                ),
              },
              {
                header: translate(language, "PAGES.USER.EXAM_TABLE.START_DATE"),
                key: "start_date",
                sortType: "date",
                render: (item: any) =>
                  item["start_date"] === null ? (
                    <span>-</span>
                  ) : (
                    <OverlayTrigger
                      overlay={<Tooltip>{formatDateHumanly(language, item["start_date"], true)}</Tooltip>}
                    >
                      <span>{formatDateHumanly(language, item["start_date"])}</span>
                    </OverlayTrigger>
                  ),
              },
              {
                header: translate(language, "PAGES.USER.EXAM_TABLE.DURATION"),
                key: "duration",
                render: (item: any) => {
                  if (item.type === "upload" || item.status === "created") {
                    return "-";
                  }
                  return item.duration;
                },
              },
              {
                header: translate(language, "PAGES.USER.EXAM_TABLE.STATUS"),
                key: "status",
                render: (item: any) => <span className={`p-1 rounded ${_getStatus(item.status)}`}>{item.status}</span>,
              },
              {
                header: translate(language, "PAGES.USER.EXAM_TABLE.ACTIONS"),
                key: "actions",
                render: (item: any) => (
                  <div className="d-flex align-items-baseline">
                    <a
                      className="text-decoration-none"
                      href={getEvaluationLink(item.id)}
                      title="Go to Beta"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="dst-icon-link-external-02"></i>
                    </a>
                    {item.comment && (
                      <span
                        className={`dst-icon-message-${
                          item.status === "success" ? "check" : "alert"
                        }-square d-block s-regular gray-font clickable ms-2`}
                        onClick={() => {
                          setSelectedComment({
                            content: item.comment!,
                            date: item.dt_corrected || "",
                            corrector_name: item.corrector.name,
                            corrector_id: item.corrector.id,
                          });
                          setShowModal(true);
                        }}
                      />
                    )}
                  </div>
                ),
              },
            ]}
            mock={!loaded}
          />
        ) : (
          <p>{translate(language, "PAGES.USER.EXAM_TABLE.NO_EXAM")}</p>
        )
      ) : (
        <Lister
          columns={[
            { header: translate(language, "PAGES.USER.EXAM_TABLE.NAME"), key: "name" },
            { header: translate(language, "PAGES.USER.EXAM_TABLE.TOKEN"), key: "token" },
            { header: translate(language, "PAGES.USER.EXAM_TABLE.CREATION_DATE"), key: "creation_date" },
            { header: translate(language, "PAGES.USER.EXAM_TABLE.START_DATE"), key: "start_date" },
            { header: translate(language, "PAGES.USER.EXAM_TABLE.DURATION"), key: "duration" },
            { header: translate(language, "PAGES.USER.EXAM_TABLE.STATUS"), key: "status" },
          ]}
          mock={true}
        />
      )}
      <Modal
        className="d-flex justify-content-center align-items-center"
        show={showModal}
        onHide={() => setShowModal(false)}
        size="lg"
      >
        <Modal.Header className="mw-100" closeButton>
          <Modal.Title className="me-3 me-md-5">
            <h3>{translate(language, "PAGES.USER.EXAM_TABLE.MODAL.TITLE")}</h3>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="text-break d-flex justify-content-start p-2 bg-light">
            <p className="m-regular" dangerouslySetInnerHTML={{ __html: selectedComment.content }}></p>
          </div>
          <div className="d-flex">
            {selectedComment.corrector_name && (
              <div className="text-break d-flex justify-content-end xs-bold pt-3">
                {translate(language, "PAGES.USER.EXAM_TABLE.MODAL.BY")}
                <span
                  className="ms-1 clickable text-decoration-underline"
                  onClick={() => {
                    setShowModal(false);
                    navigate(`/user/${selectedComment.corrector_id}`);
                  }}
                >
                  {selectedComment.corrector_name}
                </span>
              </div>
            )}
            <div className="text-break d-flex justify-content-end xs-bold pt-3 ms-1">
              {translate(language, "PAGES.USER.EXAM_TABLE.MODAL.THE")}{" "}
              {formatDateHumanly(language, selectedComment.date)}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export const UserCertification = ({
  user_data,
  loaded,
  language,
  refreshUserCertifications,
}: {
  user_data: any;
  loaded: boolean;
  language: string;
  refreshUserCertifications: Function;
}): JSX.Element => {
  return (
    <div className="w-100">
      <h2>{translate(language, "PAGES.USER.CERTIFICATION.TITLE")}</h2>
      <div className="scrollable py-3 mb-3" id="certif-scrollable">
        <div className="d-inline-flex flex-nowrap">
          {loaded ? (
            user_data.user_certifs.length > 0 ? (
              user_data.user_certifs.map((certif: any, index: number) => (
                <CertifBlock
                  key={index}
                  attempts={certif.attempt_count}
                  certifDate={certif.dt_created}
                  documentId={certif.document_id}
                  filename={certif.filename}
                  mainText={certif.certification_name}
                  remarkText={certif.duration ? formatMinutesHourly(certif.duration) : ""}
                  topLeftTitle={certif.sprint_name}
                  userFullName={`${user_data.first_name} ${user_data.last_name}`}
                  refreshUserCertifications={refreshUserCertifications}
                />
              ))
            ) : (
              <p>{translate(language, "PAGES.USER.CERTIFICATION.NO_CERTIFICATION")}</p>
            )
          ) : (
            [...Array(5)].map((_, index: number) => <CertifBlock mock={true} key={`mock-certif-block-${index}`} />)
          )}
        </div>
      </div>
    </div>
  );
};

export const UserNotes = ({
  user_data,
  language,
  loaded,
  setUserData,
  user_email,
  activeNote,
  setActiveNote,
}: {
  user_data: any;
  language: string;
  loaded: boolean;
  setUserData: Function;
  user_email: string;
  activeNote: Note | null;
  setActiveNote: Function;
}): JSX.Element => {
  const [isEditing, setIsEditing] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const pinnedNotes = user_data.user_notes?.filter((note: Note) => !!note.is_pinned);
  const unpinnedNotes = user_data.user_notes?.filter((note: Note) => !note.is_pinned);

  const _selectNote = (note: Note) => {
    setActiveNote(note);
    setIsEditing(false);
  };

  const _addNote = (note: Note) => {
    setActiveNote(note);
    setIsEditing(false);
    createNote(user_data.id, user_email, language, setUserData, setActiveNote, setIsEditing);
  };

  const _deleteNote = (id: string) => {
    deleteNote(user_data.id, id, setUserData, () => setActiveNote(null));
  };

  const _saveNote = (updatedNote: Note, is_pinning = false) => {
    if (is_pinning) {
      togglePinNote(user_data.id, updatedNote, setUserData, (new_note: Note) => {
        setActiveNote(new_note);
        setIsEditing(false);
        setIsWaiting(false);
      });
    } else {
      updateNote(user_data.id, user_email, language, updatedNote, setUserData, (new_note: Note) => {
        setActiveNote(new_note);
        setIsEditing(true);
        setIsWaiting(false);
      });
    }
  };

  return loaded ? (
    <Row className="w-100">
      <Col xs={3}>
        <DstButton
          value={translate(language, "PAGES.USER.NOTE.ADD_NOTE")}
          btnImageBefore={<Plus />}
          clickFunction={_addNote}
          btnClass="w-100"
        />
        {!!pinnedNotes.length && (
          <React.Fragment>
            <div className="my-3 darkgray-font s-bold">
              <Pin />
              {translate(language, "PAGES.USER.NOTE.PINNED")}
            </div>
            <NotesList notes={pinnedNotes} selectNote={_selectNote} activeNote={activeNote} deleteNote={_deleteNote} />
          </React.Fragment>
        )}
        <div className="my-3 darkgray-font s-bold">
          {translate(language, "PAGES.USER.NOTE.ALL_NOTE")}
          {isWaiting ? (
            <span className="ms-2 lightgray-font">
              {translate(language, "PAGES.USER.NOTE.SYNCHRONIZING")}{" "}
              <Spinner className="ms-1 spinner-in-text" size="sm" />
            </span>
          ) : (
            ""
          )}
        </div>
        {user_data.user_notes.length ? (
          <NotesList notes={unpinnedNotes} selectNote={_selectNote} activeNote={activeNote} deleteNote={_deleteNote} />
        ) : (
          <p>{translate(language, "PAGES.USER.NOTE.NO_NOTE")}</p>
        )}
      </Col>
      <Col xs={9}>
        {activeNote ? (
          <NoteContent
            note={activeNote}
            deleteNote={_deleteNote}
            saveNote={_saveNote}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            setIsWaiting={setIsWaiting}
            language={language}
          />
        ) : (
          <div>{translate(language, "PAGES.USER.NOTE.SELECT_NOTE")}</div>
        )}
      </Col>
    </Row>
  ) : (
    <Row className="w-100">
      <Col xs={3}>
        <DstButton mock btnClass="w-100" />
        <Placeholder as="div" animation="glow">
          <Placeholder xs={6} role="mockup" className="mt-2 mb-4" />
          <Placeholder xs={12} style={{ height: 550 }} />
        </Placeholder>
      </Col>
      <Col xs={9}>
        <Placeholder as="div" animation="glow">
          <Placeholder xs={6} role="mockup" />
        </Placeholder>
      </Col>
    </Row>
  );
};

export const UserMessages = ({
  user_data,
  loaded,
  language,
}: {
  user_data: any;
  loaded: boolean;
  language: string;
}): JSX.Element => {
  const [showPopup, setShowPopup] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState<any | null>(null);
  const [searchQuery, setSearchQuery] = useState("");

  const openMessagePopup = (messageId: string) => {
    const message = user_data.user_messages?.find((m: any) => m.id === messageId);
    if (message) {
      setSelectedMessage(message);
      setShowPopup(true);
    } else {
      console.error("Message not found for ID:", messageId);
    }
  };

  const filteredMessages =
    user_data.user_messages?.filter(
      (message: any) =>
        message.author.toLowerCase().includes(searchQuery.toLowerCase()) ||
        message.content.toLowerCase().includes(searchQuery.toLowerCase())
    ) || [];

  const columns = [
    {
      key: "author",
      header: translate(language, "PAGES.USER.MESSAGE.AUTHOR"),
    },
    {
      key: "date",
      header: translate(language, "PAGES.USER.MESSAGE.SENDING_DATE"),
      render: (item: any) => (
        <div>
          <OverlayTrigger overlay={<Tooltip>{formatDateHumanly(language, item["date"], true)}</Tooltip>}>
            <span>{formatDateHumanly(language, item["date"])}</span>
          </OverlayTrigger>
        </div>
      ),
    },
    {
      key: "status",
      header: translate(language, "PAGES.USER.MESSAGE.STATUS"),
      render: (item: any) => (
        <span className={`p-1 rounded ${!!item.dt_seen ? "status-green" : "status-red"}`}>
          {translate(language, `PAGES.USER.MESSAGE.${!item.dt_seen ? "NOT_" : ""}READ`)}
        </span>
      ),
    },
    {
      key: "content",
      header: translate(language, "PAGES.USER.MESSAGE.CONTENT"),
      render: (item: any) => <div dangerouslySetInnerHTML={{ __html: item.content }} />,
    },
  ];

  return (
    <React.Fragment>
      {loaded ? (
        <React.Fragment>
          <div className="w-fit">
            <ListerSearch setSearchQuery={setSearchQuery} />
          </div>
          <Lister
            items={filteredMessages.map((message: any) => ({
              ...message,
            }))}
            columns={columns}
            additionalButton={(item: any) => (
              <span className="clickable" onClick={() => openMessagePopup(item.id)}>
                <Search />
              </span>
            )}
            pagination={true}
            mock={!loaded}
          />
          {showPopup && (
            <Popup
              show={showPopup}
              onClose={() => setShowPopup(false)}
              modalTitle={translate(language, "PAGES.USER.MESSAGE.POPUP_TITLE")}
              size="xl"
              fullscreen="lg-down"
              scrollable={true}
            >
              <div className="m-regular">
                <Row className="mb-2">
                  <Col>
                    <h3>{translate(language, "PAGES.USER.MESSAGE.AUTHOR")}</h3>
                    <DstButton
                      variant="link"
                      btnClass="m-bold p-0"
                      clickFunction={() => window.open(`/user/${selectedMessage.author_id}`, "_blank")}
                      value={selectedMessage.author}
                    />
                  </Col>
                  <Col>
                    <h3>{translate(language, "PAGES.USER.MESSAGE.EMAIL")}</h3>
                    <p>{selectedMessage.email}</p>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <h3>{translate(language, "PAGES.USER.MESSAGE.SENDING_DATE")}</h3>
                    <p>{formatDateHumanly(language, new Date(selectedMessage.date).toISOString())}</p>
                  </Col>
                  <Col>
                    <h3>{translate(language, "PAGES.USER.MESSAGE.STATUS")}</h3>
                    <p>
                      {selectedMessage.dt_seen ? (
                        <span className="badge rounded-pill text-bg-success m-regular">
                          {translate(language, "PAGES.USER.MESSAGE.READ")} -{" "}
                          {new Date(selectedMessage.dt_seen).toLocaleString()}
                        </span>
                      ) : (
                        <span className="badge rounded-pill text-bg-danger m-regular">
                          {translate(language, "PAGES.USER.MESSAGE.NOT_READ")}
                        </span>
                      )}
                    </p>
                  </Col>
                </Row>
                <h3>{translate(language, "PAGES.USER.MESSAGE.CONTENT")}</h3>
                <div className="p-3 lightgray-background border-rounded">
                  <div dangerouslySetInnerHTML={{ __html: selectedMessage.fullContent }} />
                </div>
              </div>
            </Popup>
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <ListerSearch mock setSearchQuery={setSearchQuery} />
          <Lister
            columns={[
              { header: translate(language, "PAGES.USER.MESSAGE.AUTHOR"), key: "author" },
              { header: translate(language, "PAGES.USER.MESSAGE.SENDING_DATE"), key: "date" },
              { header: translate(language, "PAGES.USER.MESSAGE.STATUS"), key: "status" },
              { header: translate(language, "PAGES.USER.MESSAGE.CONTENT"), key: "content" },
            ]}
            pagination
            mock={true}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
